import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/reset.css";
import './App.css'
import './index.css'
import { Store, persistor } from "./store";
import { QueryClient, QueryClientProvider } from "react-query";
import { PersistGate } from 'redux-persist/integration/react';
const BASENAME = process.env.REACT_APP_BASE_NAME

const queryClient = new QueryClient();
ReactDOM.render(
  // <React.StrictMode>
  // <Router history={Utils.history}>

  <Router basename={BASENAME}>
    <QueryClientProvider client={queryClient}>
      <Provider store={Store}	 >
        <PersistGate loading={null} persistor={persistor}>
        <App />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </Router>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// if (window.location.pathname === "/") {
//   window.location.replace(BASENAME)
// }

reportWebVitals();

