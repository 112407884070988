import React, { useEffect, useState } from "react";
import { Button, Loader } from "../../components/index.js";
import { Row, Col } from "antd";
// import check from 'path/to/check'; 
import AvatarBasic from "../../components/Avatar/index.js";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/Card/BasicCard.tsx";
import { Text } from '../../components/index.js'
import BasicDatePicker from '../../components/Picker/BasicDatePicker.jsx'
import RangeDatePicker from "../../components/Picker/RangePicker.jsx";
import './style.css'
import dayjs, { Dayjs } from 'dayjs';
import { Colors } from "../../config/index.js";
import UserActivityChart from "../../components/Charts/UserActivityChart.tsx";
import DoughnutChart from "../../components/Charts/DoughnutChart.js";
// import Select from '../../components/Select/SelectButton.tsx'
// import DashboardAction from '../../store/actions/DashboardAction.js'
import { useDispatch, useSelector } from 'react-redux'
// REACT ICONS
import usercount from '../../assets/images/usercount.png'
import chat from '../../assets/images/chat.png'
import comment from '../../assets/images/comment.png'
import gallery from '../../assets/images/gallery.png'
import arrow from '../../assets/images/arrow.png'
import check from '../../assets/images/check.png'
import card1icon from '../../assets/images/card1icon.png'
import card2icon from '../../assets/images/card2icon.png'
import card3icon from '../../assets/images/card3icon.png'
import { UserOutlined } from '@ant-design/icons'
import { MdMoreHoriz } from "react-icons/md";
import { CiLocationOn } from 'react-icons/ci'
import { IoMdMore } from "react-icons/io";
// =============================
import { Select } from 'antd';
import { CalendarOutlined, BarsOutlined } from '@ant-design/icons'; 




interface DashboardDataState {
    dashboardCounts?: {
        data?: {
            commentsCount?: {
                totalComments?: number;
                today?: number;
                yesterday?: number;
            };
            usersCount?: {
                totalUsers?: number;
                today?: number;
                yesterday?: number;
            };
            postsCount?: {
                totalPosts?: number;
                today?: number;
                yesterday?: number;
            };
            messagesCount?: number;
        };
    };
    newUsersAndActivity?: {
        data?: {
            newUsers?: Array<{
                imageThumbUrl?: string;
                fullName?: string;
            }>;
            activity?: Array<{
                data: string;
                createdAt?: string;
            }>;
        };
    };
    graphData?: {
        data?: any;
    };
}
const Dashboard = () => {

    const [dashboardDataState, setDashboardDataState] = useState<DashboardDataState | null>(null);
    const [selectedDate, setselectedDate] = useState<Dayjs | null>(null);
    const [selectedGraph, setSelectedGraph] = useState<string>('weekly');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const DashboardData = useSelector((state: any) => state?.DashboardReducer);
    const DataLoader = useSelector((state: any) => state?.DashboardReducer?.loader);

    // useEffect(() => {
    //     getDashData();
    //     dispatch(DashboardAction.GetDashboardActivity());
    // }, [dispatch]);

    // useEffect(() => {
    //     dispatch(DashboardAction.GetDashboardGraph(selectedGraph));
    // }, [dispatch, selectedGraph]);

    useEffect(() => {
        if (DashboardData) {
            setDashboardDataState(DashboardData);
        }
    }, [DashboardData]);

    const totalComments = dashboardDataState?.dashboardCounts?.data?.commentsCount?.totalComments || 0;
    const totalUsers = dashboardDataState?.dashboardCounts?.data?.usersCount?.totalUsers || 54;
    const totalPosts = dashboardDataState?.dashboardCounts?.data?.postsCount?.totalPosts || 0;
    const totalmessages = dashboardDataState?.dashboardCounts?.data?.messagesCount || 0;

    const todayCommentCount = dashboardDataState?.dashboardCounts?.data?.commentsCount?.today || 0;
    const yesterdayCommentCount = dashboardDataState?.dashboardCounts?.data?.commentsCount?.yesterday || 0;
    const commentPercentage = ((todayCommentCount - yesterdayCommentCount) / yesterdayCommentCount) * 100 || 0;

    const todayPostsCount = dashboardDataState?.dashboardCounts?.data?.postsCount?.today || 0;
    const yesterdaypostsCount = dashboardDataState?.dashboardCounts?.data?.postsCount?.yesterday || 0;
    const postPercentage = ((todayPostsCount - yesterdaypostsCount) / yesterdaypostsCount) * 100 || 0;

    const todayUserCount = dashboardDataState?.dashboardCounts?.data?.usersCount?.today || 0;
    const yesterdayUserCount = dashboardDataState?.dashboardCounts?.data?.usersCount?.yesterday || 0;
    let userPercentage = yesterdayUserCount > 0 ? ((todayUserCount - yesterdayUserCount) / yesterdayUserCount) * 100 || 0 : todayUserCount * 100;

    const format = 'YYYY-MM-DD';
    // const getDashData = (date?: Dayjs) => {
    //     if (date) {
    //         const formattedDate = date.format(format);
    //         dispatch(DashboardAction.GetDashboardCounts(formattedDate));
    //         setselectedDate(date);
    //     } else {
    //         dispatch(DashboardAction.GetDashboardCounts());
    //     }
    // };

    const datePickerChange = (date: Dayjs | null) => {
        if (date) {
            // getDashData(date);
        }
    };

    const dateRangePicker = (date: any) => {
        alert(date);
    };

    const graphItems = [
        { value: 'weekly', label: 'weekly' },
        { value: 'monthly', label: 'monthly' },
        { value: 'yearly', label: 'yearly' }
    ];

    const users = dashboardDataState?.newUsersAndActivity?.data?.newUsers;

    const activity = dashboardDataState?.newUsersAndActivity?.data?.activity;

    // const graphSelectChange = (value: string) => {
    //     setSelectedGraph(value);
    //     dispatch(DashboardAction.GetDashboardGraph(value));
    // };

    const graphData = dashboardDataState?.graphData?.data;

    // ==================================================
    const { Option } = Select;

const options = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
];


  const handleChange = (value: string) => {
    console.log(`Selected value: ${value}`);
  };

    return (
        <>
            {DataLoader ? <Loader.Circular className="border-0 border-red-500 w-full" size={60} color={Colors.Green} />
                :
                <>
                    <Row className=''>
                        <Col span={24} className="flex items-center justify-between flex-wrap" >
                            <Text.Basic className="text-[1.2rem] md:text-[1.4rem] font-bold" text='Dashboard' />
                            {/* <BasicDatePicker defaultValue={dayjs()} value={selectedDate} format={format} onChange={(date) => datePickerChange(date)} className="outline-none border-0 p-2" /> */}
                        </Col>
                    </Row>

                    <Row className="my-4 py-2 border-0 border-red-500" gutter={[16, 8]} justify={"space-between"} >
                        <Col lg={8} md={12} sm={12} xs={24} className="cursor-pointer mb-3 md:mb-0">
                            <BasicCard style={{ borderRadius: "1rem" }} content={
                                <Row className="px-4">
                                    <Col className="flex-grow border-0 border-red-500">
                                        <p className="text-[1rem] font-medium" style={{ color: Colors.GrayText }} >Total Users</p>
                                        <p className="text-[2rem] font-bold">{totalUsers.toLocaleString()}</p>
                                    </Col>
                                    <Col className="flex items-center justify-end border-0 border-red-500">
                                        <div className="flex gap-1 p-6 rounded-xl bg-[#cef7f4]">
                                            <img className="h-6" src={card1icon} />
                                        </div>
                                    </Col>
                                </Row>
                            } />
                        </Col>
                        <Col lg={8} md={12} sm={12} xs={24} className="cursor-pointer mb-3 md:mb-0">
                            <BasicCard style={{ borderRadius: "1rem" }} content={
                                <Row className="px-4">
                                    <Col className="flex-grow border-0 border-red-500">
                                        <p className="text-[1rem] font-medium" style={{ color: Colors.GrayText }} >Total Documents</p>
                                        <p className="text-[2rem] font-bold">{totalUsers.toLocaleString()}</p>
                                    </Col>
                                    <Col className="flex items-center justify-end border-0 border-red-500">
                                        <div className="flex gap-1 p-6 rounded-xl bg-[#fed9e5]">
                                            <img className="h-6" src={card2icon} />
                                        </div>
                                    </Col>
                                </Row>
                            } />
                        </Col>
                        <Col lg={8} md={12} sm={12} xs={24} className="cursor-pointer mb-3 md:mb-0">
                            <BasicCard style={{ borderRadius: "1rem" }} content={
                                <Row className="px-4">
                                    <Col className="flex-grow border-0 border-red-500">
                                        <p className="text-[1rem] font-medium" style={{ color: Colors.GrayText }} >Total sub-admins</p>
                                        <p className="text-[2rem] font-bold">{totalUsers.toLocaleString()}</p>
                                    </Col>
                                    <Col className="flex items-center justify-end border-0 border-red-500">
                                        <div className="flex gap-1 p-6 rounded-xl bg-[#d3deff]">
                                            <img className="h-6" src={card3icon} />
                                        </div>
                                    </Col>
                                </Row>
                            } />
                        </Col>


                    </Row>

                    <Row className="mb-4  border-0 border-red-500" justify={"space-between"} >
                        <Col lg={15} md={24} sm={24} className="p-4 mb-4 rounded-lg lg:mb-0 min-w-[700px] overflow-x-auto border-0 border-red-500" style={{ backgroundColor: Colors.White }}>
                            <Row className="">
                                <Col span={24} className="flex items-center justify-between flex-wrap mb-4" >
                                    <Text.Basic className="font-medium" style={{ fontSize: '1.1rem' }} text='Apple and Google Users' />
                                    {/* <RangeDatePicker style={{ backgroundColor: Colors.LightGray }} defaultValue={dayjs()} onChange={(date) => dateRangePicker(date)} className="outline-none border-0 p-2" /> */}
                                    <div className="flex gap-2">
                                        <div className="flex gap-6">
                                            <div className="flex items-center">
                                                <span className="text-[#16DBCC] bg-[#16DBCC] p-2 rounded-full"></span>
                                                <span className="ml-2 text-xs font-medium">Android users</span>
                                            </div>
                                            <div className="flex items-center">
                                                <span className="text-[#1814F3] bg-[#1814F3] p-2 rounded-full"></span>
                                                <span className="ml-2 text-xs font-medium">iOS users</span>
                                            </div>
                                        </div>
                                        {/* <Select values={graphItems} value={selectedGraph} className={'w-32'} defaultValue={'weekly'} handleChange={graphSelectChange} /> */}
                                        <div className="border-1 border-gray-500 rounded-lg">
                                            
                                        <Select  defaultValue="weekly" style={{ width: 150 }} onChange={handleChange}>
                                            {options.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    <CalendarOutlined style={{ marginRight: 5 }} /> {option.label} {/* Add CalendarOutlined icon before */}
                                                    <BarsOutlined style={{ marginLeft: 5 }} /> {/* Add BarsOutlined icon after */}
                                                </Option>
                                            ))}
                                        </Select>
                                        </div>
                                    </div>
                                </Col>
                                <UserActivityChart data={graphData} selectedGraph={selectedGraph} />


                            </Row>
                        </Col>

                        <Col lg={8} md={24} sm={24} className="p-4 rounded-lg" style={{ backgroundColor: Colors.White }}>
                            <Row>
                                <Col span={24} className="flex items-center justify-between flex-wrap mb-4" >
                                    <Text.Basic style={{ fontWeight: '400', fontSize: '1.3rem' }} text='New User' />
                                    {/* <Select values={items} className={'w-32'} defaultValue={'weekly'} handleChange={UserChange} /> */}
                                </Col>

                                <Col span={24} className="h-96 overflow-y-scroll scrollbar-display-class">
                                    <Row className="flex align-start justify-between">
                                        {
                                            users?.map((item, key) => {
                                                return (
                                                    <Col key={key} span={24} className="p-5 flex align-center align-self-start flex-wrap gap-4 mb-2 border-b-4 border-dashed border-2 border-red-500">
                                                        {item.imageThumbUrl ? <AvatarBasic.Basic size={54} src={item.imageThumbUrl} /> : <AvatarBasic.Basic size={54} icon={<UserOutlined />} />}
                                                        <Row className="pt-0 items-center my-auto border-0 border-red-500">
                                                            <p className="inline text-sm">@{item?.fullName}</p>
                                                            <img src={check} className="w-4 h-4 ml-1 inline" />
                                                        </Row>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>




                    <Row justify={"space-between"}>
                        <Col lg={24} md={24} sm={24} className="p-4 mb-3 md:mb-0 rounded-lg" style={{ backgroundColor: Colors.White }}>
                            <Row>
                                <Col span={24} className="mb-4" >
                                    <Text.Basic style={{ fontWeight: '400', fontSize: '1.3rem' }} text='Last Activity' />
                                </Col>

                                <Col span={24} className="h-96 overflow-y-auto scrollbar-display-class">
                                    <Row className="flex align-start justify-between min-w-[900px] overflow-x-auto">
                                        {
                                            activity?.map((item, key) => {
                                                const parsedItem = JSON.parse(item.data);

                                                return (
                                                    <Col span={24} key={key} className="p-5 flex align-center align-self-start justify-between gap-4 mb-2 border-b-4 border-dashed ">
                                                        <Row align={'middle'} className="gap-2 ">
                                                            {parsedItem.imageThumbUrl ? <AvatarBasic.Basic size={54} src={parsedItem?.imageThumbUrl} /> : <AvatarBasic.Basic size={54} icon={<UserOutlined />} />}
                                                            <span className="pt-2 text-sm">{parsedItem.fullName ? parsedItem?.fullName : '-'}
                                                                {/* <p className="block text-xs" style={{ color: Colors.Gray }}>{item.action}</p> */}
                                                            </span>
                                                        </Row>

                                                        <Row className="my-4 ">
                                                            <CiLocationOn size={15} color={Colors.Green} />
                                                            <span className="inline-block align-middle text-xs" >
                                                                {parsedItem?.address ? parsedItem.address : ''}
                                                                {parsedItem?.city ? ' ' + parsedItem.city : ''}
                                                                {parsedItem?.country ? ' ' + parsedItem.country : ''}
                                                                {!parsedItem.country && !parsedItem.city && !parsedItem.address ? '-' : ''}
                                                            </span>
                                                        </Row>

                                                        <Row className="flex flex-col pt-2 ">
                                                            <span className="text-xs" style={{ color: Colors.Gray }}>Status in {item?.createdAt ? new Date(item?.createdAt).toISOString().split('T')[0] : '-'}</span>
                                                            <p className="text-xs cursor-pointer" style={{ color: Colors.Green }}>Details</p>
                                                        </Row>

                                                    </Col>
                                                )
                                            })
                                        }


                                    </Row>
                                </Col>

                            </Row>
                        </Col>

                        {/* <Col lg={8} md={24} sm={24} className="p-4 rounded-lg" style={{ backgroundColor: Colors.White }}>
                    <Row>
                        <Col span={24} className="mb-4 flex justify-between align-center" >
                            <Text.Basic style={{ fontWeight: '400', fontSize: '1.3rem' }} text='Visit Visitors' />
                            <IoMdMore className="cursor-pointer" color={Colors.Gray} />
                        </Col>
                        <DoughnutChart />
                    </Row>
                </Col> */}
                    </Row>

                </>}
        </>
        // <div>
        //     <Col span={24} className="h-96 overflow-y-scroll scrollbar-display-class">
        //         <Row className="flex align-start justify-between">
        //             {
        //                 users?.map((item, key) => {
        //                     return (
        //                         <Col key={key} span={24} className="p-5 flex align-center align-self-start flex-wrap gap-4 mb-2 border-b-4 border-dashed border-2 border-red-500">
        //                             {item.imageThumbUrl ? <AvatarBasic.Basic src={item.imageThumbUrl} size={54} /> : <AvatarBasic.Basic size={54} icon={<UserOutlined />} />}
        //                             <Row className="pt-0 items-center my-auto border-0 border-red-500">
        //                                 <p className="inline text-sm">@{item?.fullName}</p>
        //                                 <img src={check} className="w-4 h-4 ml-1 inline" />
        //                             </Row>
        //                         </Col>
        //                     )
        //                 })
        //             }
        //         </Row>
        //     </Col>
        // </div>

    )
}
export default Dashboard;