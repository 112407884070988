import React, { useState } from "react";
import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Text } from "../../components";
import { Colors } from "../../config";
// import { AppAction } from "../../store/actions";
// import './style.css'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { passwordVerification } from '../../config/Schema/Schema.ts'

// REACT ICONS
import signin from '../../assets/images/signin.png'
import email from '../../assets/images/email.png'
import passwordViewer from '../../assets/images/passwordViewer.png'
import passwordseen from '../../assets/images/passwordseen.png'
// import password from '../../assets/images/password.png'
import signinBar1 from '../../assets/images/signinBar1.png'
import signinBar2 from '../../assets/images/singinBar2.png'
import pointer from '../../assets/images/pointer.png'
import loginBg from '../../assets/images/v3ctor-01 1.png'
import loginBgEllipse from '../../assets/images/Ellipse 1.png'
import loginLogo from '../../assets/images/loginLogo.png'
import eyePassword from '../../assets/images/eyePassword.png'
import { confirmResetPassword } from "@aws-amplify/auth";
import AppAction from "../../store/actions/AppAction.js";

// import loginBgEllipse from '../../assets/images/Ellipse 1.png'



const ForgetPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState({
        passwordField1: false,
        passwordField2: false
    });
    const { state } = useLocation();
    console.log("state in forget pass", state);


    const validationSchema = passwordVerification;
    const { handleSubmit, control } = useForm({
        resolver: yupResolver(validationSchema),
    });

    async function handleConfirmResetPassword({
        username,
        confirmationCode,
        newPassword
      }) {
        try {
          const output = await confirmResetPassword({ username, confirmationCode, newPassword });
        //   navigate("/login");
            console.log('outputtt ',output)
            navigate('/dashboard')
            // dispatch(AppAction.isAuthenticated(true, () => {
                // navigate('/dashboard')
            //   }));
            // navigate('/das')
        } catch (error) {
          console.log(error);
        }
      }
    const LoginForm = (data) => {

        console.log("data i am getting signin", data)
        const { email, password } = data;

        handleConfirmResetPassword({ username: state?.email, confirmationCode: state?.otp, newPassword: password })
        // handleSignIn({ username: email, password: password });
    }

    return (
        <Row style={{ backgroundColor: Colors.BgWhite }} className="h-screen">

            <Col className=" relative opacity-95 rounded-none" lg={12} md={12} sm={24} xs={24} style={{ background: Colors.White }}>

                <Row justify={'start'} className="h-full max-[900px]:w-4/5 w-3/5 mx-auto flex flex-col justify-center items-start px-2">
                    <Col className=" relative opacity-95 items-center justify-center mx-auto mb-0 md:mb-4 border-0 border-red-500">
                        <img src={loginLogo} className="w-auto h-[8rem] md:h-auto md:w-2/6 mx-auto" />
                        <Text.Basic text='Forgot Password' className="block text-center text-[1.5rem] md:text-[2rem] " style={{ fontWeight: 700, color: Colors.DarkBlue, }} />
                        <Text.Basic text='Set the new password for your account.' className="block text-center" style={{ fontWeight: 500, color: Colors.DarkBlue, fontSize: '1rem' }} />
                    </Col>

                    {/* // 2 bars on the right column. */}
                    {/* <img src={signinBar1} className="absolute h-full left-12  opacity-100 z-[-1]" />
                    <img src={signinBar2} className="absolute h-full right-12 opacity-100 z-[-1]" /> */}


                    <form className="mt-6 w-full" >

                        <label className="font-semibold">Set New Password</label>
                        <Row className="w-full pb-5">
                            <Controller
                                name="password"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Col span={24} className="relative">
                                        <input type={showPassword.passwordField1 ? 'text' : 'password'} style={{ border: `1px solid #D7DBE8`, background: Colors.LightWhite }} className="px-2 py-3 rounded w-full outline-0" placeholder="Enter your password"  {...field} />
                                        <img className="absolute h-4 w-4 right-4 top-4 cursor-pointer" src={showPassword?.passwordField1 ? eyePassword : eyePassword} onClick={() => setShowPassword({ ...showPassword, passwordField1: !showPassword.passwordField1 })} />
                                        {fieldState.error && <p style={{ color: Colors.Error }}>{fieldState.error.message}</p>}
                                    </Col>
                                )}
                            />
                        </Row>

                        {/* <label className="font-semibold">Confirm Password</label> */}
                        {/* <Row className="w-full">
                            <Controller
                                name="confirm_password"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Col span={24} className="relative">
                                        <input type={showPassword.passwordField2 ? 'text' : 'password'} style={{ border: `1px solid #D7DBE8`, background: Colors.LightWhite }} className="px-2 py-3 rounded w-full outline-0" placeholder="Enter your confirm password" {...field} />
                                        <img className="absolute h-4 w-4 right-4 top-4 cursor-pointer" src={showPassword?.passwordField2 ? eyePassword : eyePassword} onClick={() => setShowPassword({ ...showPassword, passwordField2: !showPassword.passwordField2 })} />
                                        {fieldState.error && <p style={{ color: Colors.Error }}>{fieldState.error.message}</p>}
                                    </Col>
                                )}
                            />
                        </Row> */}

                        {/* <input type='submit'  value={'Update'} className='w-full mt-4 h-11 rounded cursor-pointer hover:opacity-95' style={{ background: "linear-gradient(113.29deg, #5AF4FF -6.79%, #2F7AAC 79.93%)", color: Colors.White }} /> */}
                        <button
                            type='submit'
                            onClick={handleSubmit((data) => LoginForm(data))}
                            value='Login'
                            // disabled={loader ? true : false} 
                            className='w-full mt-4 h-11 rounded cursor-pointer hover:opacity-95'
                            style={{ background: "linear-gradient(113.29deg, #5AF4FF -6.79%, #2F7AAC 79.93%)", color: Colors.White }}
                        >
                            submit
                        </button>
                    </form>

                    <Row className="w-full mx-auto mt-5" >
                        <p className="mx-auto cursor-pointer" onClick={() => navigate('/login')}>Back to login</p>
                    </Row>

                </Row>

            </Col>

            <Col className="hidden md:flex flex-col justify-center align-center max-[900px]:px-4 md:rounded-l-3xl border-0 border-red-500" lg={12} md={12} sm={0} xs={0} style={{ background: Colors.DarkBlue }}>
                <Row className="h-[31rem] relative max-[767px]:h-fit max-[767px]:pt-3 w-full mb-3  border-0 border-red-500" align={'center'}>
                    <img src={loginBgEllipse} className="w-1/2 h-full md:h-[85%] md:w-full object-contain object-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />
                    <img src={loginBg} className="w-1/2 h-full md:h-[100%] md:w-full object-contain object-center" />
                </Row>
                <Row align={'center'} justify={'center'} className="signin-logo flex items-center	flex-col mx-auto ">
                    <Text.Basic text="Smart Scanning Solution" className="min-[900px]:text-[2rem] text-[1.5rem] text-center font-semibold my-1 pt-1" style={{ color: Colors.White }}></Text.Basic>
                    {/* <img className="self-start" src={pointer} width={25} height={20} /> */}
                    <Text.Basic
                        text="Transform Documents into Audible or Editable Text"
                        className="text-base mx-6 max-[900px]:mx-0 font-extralight my-1 pt-1 text-center"
                        style={{ color: Colors.White }}>

                    </Text.Basic>


                </Row>
            </Col>


        </Row>
    )
};

export default ForgetPassword;
