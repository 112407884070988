export const AUTHENTICATED = "AUTHENTICATED"

export const SIGNIN = "SIGNIN"
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS"
export const SIGNIN_FAILURE = "SIGNIN_FAILURE"



export const LOGOUT = "LOGOUT"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAILURE = "LOGOUT_FAILURE"

export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE"

export const ADD_POST = "ADD_POST"
export const ADD_POST_SUCCESS = "ADD_POST_SUCCESS"
export const ADD_POST_FAILURE = "ADD_POST_FAILURE"

export const VERIFIED_OTP = 'VERIFIED_OTP'
export const VERIFIED_OTP_SUCCESS = 'VERIFIED_OTP_SUCCESS'
export const VERIFIED_OTP_FAILURE = 'VERIFIED_OTP_FAILURE'

export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD' 
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'

export const USER_UPDATE = "USER_UPDATE"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE"

export const USER_DETAIL = 'USER_DETAIL'
export const USER_DETAIL_SUCCESS ='USER_DETAIL_SUCCESS'
export const USER_DETAIL_FAILURE = 'USER_DETAIL_FAILURE'

export const USER_DETAIL_POST = 'USER_DETAIL_POST'
export const USER_DETAIL_POST_SUCCESS ='USER_DETAIL_POST_SUCCESS'
export const USER_DETAIL_POST_FAILURE = 'USER_DETAIL_POST_FAILURE'

export const GET_REPORT_USERS = 'GET_REPORT_USERS'
export const GET_REPORT_USERS_SUCCESS = 'GET_REPORT_USERS_SUCCESS'
export const GET_REPORT_USERS_FAILURE = 'GET_REPORT_USERS_FAILURE'

export const REPORT_USER_UPDATE = "REPORT_USER_UPDATE"
export const REPORT_USER_UPDATE_SUCCESS = "REPORT_USER_UPDATE_SUCCESS"
export const REPORT_USER_UPDATE_FAILURE = "REPORT_USER_UPDATE_FAILURE"

export const REPORT_USER_DETAIL = 'REPORT_USER_DETAIL'
export const REPORT_USER_DETAIL_SUCCESS ='REPORT_USER_DETAIL_SUCCESS'
export const REPORT_USER_DETAIL_FAILURE = 'REPORT_USER_DETAIL_FAILURE'

export const GET_CONTENT_POSTS = 'GET_CONTENT_POSTS'
export const GET_CONTENT_POSTS_SUCCESS = 'GET_CONTENT_POSTS_SUCCESS'
export const GET_CONTENT_POSTS_FAILURE = 'GET_CONTENT_POSTS_FAILURE'

export const POSTS_CONTENT_UPDATE = "POSTS_CONTENT_UPDATE"
export const POSTS_CONTENT_UPDATE_SUCCESS = "POSTS_CONTENT_UPDATE_SUCCESS"
export const POSTS_CONTENT_UPDATE_FAILURE = "POSTS_CONTENT_UPDATE_FAILURE"

export const POSTS_CONTENT_DETAIL = 'POSTS_CONTENT_DETAIL'
export const POSTS_CONTENT_DETAIL_SUCCESS ='POSTS_CONTENT_DETAIL_SUCCESS'
export const POSTS_CONTENT_DETAIL_FAILURE = 'POSTS_CONTENT_DETAIL_FAILURE'

export const GET_CONTENT_COMMENTS = 'GET_CONTENT_COMMENTS'
export const GET_CONTENT_COMMENTS_SUCCESS = 'GET_CONTENT_COMMENTS_SUCCESS'
export const GET_CONTENT_COMMENTS_FAILURE = 'GET_CONTENT_COMMENTS_FAILURE'

export const COMMENTS_CONTENT_UPDATE = "COMMENTS_CONTENT_UPDATE"
export const COMMENTS_CONTENT_UPDATE_SUCCESS = "COMMENTS_CONTENT_UPDATE_SUCCESS"
export const COMMENTS_CONTENT_UPDATE_FAILURE = "COMMENTS_CONTENT_UPDATE_FAILURE"

export const COMMENTS_CONTENT_DETAIL = 'COMMENTS_CONTENT_DETAIL'
export const COMMENTS_CONTENT_DETAIL_SUCCESS ='COMMENTS_CONTENT_DETAIL_SUCCESS'
export const COMMENTS_CONTENT_DETAIL_FAILURE = 'COMMENTS_CONTENT_DETAIL_FAILURE'

export const GET_POSTS = "GET_POSTS"
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS"
export const GET_POSTS_FAILURE = "GET_POSTS_FAILURE"

// get reported chats 
export const GET_REPORT_CHATS = "GET_REPORT_CHATS"
export const GET_REPORT_CHATS_SUCCESS = "GET_REPORT_CHATS_SUCCESS"
export const GET_REPORT_CHATS_FAILURE = "GET_REPORT_CHATS_FAILURE"

export const CHATS_CONTENT_DETAIL = "CHATS_CONTENT_DETAIL"
export const CHATS_CONTENT_DETAIL_SUCCESS = "CHATS_CONTENT_DETAIL_SUCCESS"
export const CHATS_CONTENT_DETAIL_FAILURE = "CHATS_CONTENT_DETAIL_FAILURE"

// Contact Support Page
export const GET_CONTACT_USERS = "GET_CONTACT_USERS"
export const GET_CONTACT_USERS_SUCCESS = "GET_CONTACT_USERS_SUCCESS"
export const GET_CONTACT_USERS_FAILURE = "GET_CONTACT_USERS_FAILURE"

export const POST_CONTACT_USERS_RESPOND = "POST_CONTACT_USERS_RESPOND"
export const POST_CONTACT_USERS_RESPOND_SUCCESS = "POST_CONTACT_USERS_RESPOND_SUCCESS"
export const POST_CONTACT_USERS_RESPOND_FAILURE = "POST_CONTACT_USERS_RESPOND_FAILURE"

// Forum Page
export const GET_FORUM_LIST = "GET_FORUM_LIST"
export const GET_FORUM_LIST_SUCCESS = "GET_FORUM_LIST_SUCCESS"
export const GET_FORUM_LIST_FAILURE = "GET_FORUM_LIST_FAILURE"

export const GET_FORUM_PARTICIPANTS = "GET_FORUM_PARTICIPANTS"
export const GET_FORUM_PARTICIPANTS_SUCCESS = "GET_FORUM_PARTICIPANTS_SUCCESS"
export const GET_FORUM_PARTICIPANTS_FAILURE = "GET_FORUM_PARTICIPANTS_FAILURE"

export const GET_FORUM_DETAILS = "GET_FORUM_DETAILS"
export const GET_FORUM_DETAILS_SUCCESS = "GET_FORUM_DETAILS_SUCCESS"
export const GET_FORUM_DETAILS_FAILURE = "GET_FORUM_DETAILS_FAILURE"

export const GET_FORUM_REPLIES = "GET_FORUM_REPLIES"
export const GET_FORUM_REPLIES_SUCCESS = "GET_FORUM_REPLIES_SUCCESS"
export const GET_FORUM_REPLIES_FAILURE = "GET_FORUM_REPLIES_FAILURE"

export const POST_PHOTO_DESCRIPTION = "POST_PHOTO_DESCRIPTION"
export const POST_PHOTO_DESCRIPTION_SUCCESS = "POST_PHOTO_DESCRIPTION_SUCCESS"
export const POST_PHOTO_DESCRIPTION_FAILURE = "POST_PHOTO_DESCRIPTION_FAILURE"

export const UPLOAD_PHOTO = "UPLOAD_PHOTO"
export const UPLOAD_PHOTO_SUCCESS = "UPLOAD_PHOTO_SUCCESS"
export const UPLOAD_PHOTO_FAILURE = "UPLOAD_PHOTO_FAILURE"

export const REMOVE_FORUM_PARTICIPANTS = "REMOVE_FORUM_PARTICIPANTS"
export const REMOVE_FORUM_PARTICIPANTS_SUCCESS = "REMOVE_FORUM_PARTICIPANTS_SUCCESS"
export const REMOVE_FORUM_PARTICIPANTS_FAILURE = "REMOVE_FORUM_PARTICIPANTS_FAILURE"

export const DELETE_FORUM = "DELETE_FORUM"
export const DELETE_FORUM_SUCCESS = "DELETE_FORUM_SUCCESS"
export const DELETE_FORUM_FAILURE = "DELETE_FORUM_FAILURE"

export const GET_REPORTED_FORUM_COMMENTS = "GET_REPORTED_FORUM_COMMENTS"
export const GET_REPORTED_FORUM_COMMENTS_SUCCESS = "GET_REPORTED_FORUM_COMMENTS_SUCCESS"
export const GET_REPORTED_FORUM_COMMENTS_FAILURE = "GET_REPORTED_FORUM_COMMENTS_FAILURE"

export const GET_REPORTED_COMMENT_DETAIL = "GET_REPORTED_COMMENT_DETAIL"
export const GET_REPORTED_COMMENT_DETAIL_SUCCESS = "GET_REPORTED_COMMENT_DETAIL_SUCCESS"
export const GET_REPORTED_COMMENT_DETAIL_FAILURE = "GET_REPORTED_COMMENT_DETAIL_FAILURE"

export const DELETE_REPORTED_COMMENT = "DELETE_REPORTED_COMMENT"
export const DELETE_REPORTED_COMMENT_SUCCESS = "DELETE_REPORTED_COMMENT_SUCCESS"
export const DELETE_REPORTED_COMMENT_FAILURE = "DELETE_REPORTED_COMMENT_FAILURE"

//Event Mangement
export const ALL_EVENT_LIST = "ALL_EVENT_LIST"
export const ALL_EVENT_LIST_SUCCESS = "ALL_EVENT_LIST_SUCCESS"
export const ALL_EVENT_LIST_FAILURE = "ALL_EVENT_LIST_FAILURE"

export const EVENT_UPDATE = "EVENT_UPDATE"
export const EVENT_UPDATE_SUCCESS = "EVENT_UPDATE_SUCCESS"
export const EVENT_UPDATE_FAILURE = "EVENT_UPDATE_FAILURE"

export const CREATE_EVENT = "CREATE_EVENT"
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS"
export const CREATE_EVENT_FAILURE = "CREATE_EVENT_FAILURE"

export const DELETE_EVENT = "DELETE_EVENT"
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS"
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE"

//Dashboard
export const DASHBOARD_COUNTS = "DASHBOARD_COUNTS"
export const DASHBOARD_COUNTS_SUCCESS = "DASHBOARD_COUNTS_SUCCESS"
export const DASHBOARD_COUNTS_FAILURE = "DASHBOARD_COUNTS_FAILURE"

export const DASHBOARD_ACTIVITY = "DASHBOARD_ACTIVITY"
export const DASHBOARD_ACTIVITY_SUCCESS = "DASHBOARD_ACTIVITY_SUCCESS"
export const DASHBOARD_ACTIVITY_FAILURE = "DASHBOARD_ACTIVITY_FAILURE"

export const DASHBOARD_GRAPH = "DASHBOARD_GRAPH"
export const DASHBOARD_GRAPH_SUCCESS = "DASHBOARD_GRAPH_SUCCESS"
export const DASHBOARD_GRAPH_FAILURE = "DASHBOARD_GRAPH_FAILURE"

//Loader
export const LOADER_TRUE = 'LOADER_TRUE';
export const LOADER_FALSE = 'LOADER_FALSE';