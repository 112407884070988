import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Button } from "antd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Text } from "../../components";
import { Colors } from "../../config";
// import { AppAction } from "../../store/actions";
import './style.css'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputOtp from "../../components/Otp/index.tsx";
import loginBg from '../../assets/images/v3ctor-01 1.png'
import loginBgEllipse from '../../assets/images/Ellipse 1.png'
import loginLogo from '../../assets/images/loginLogo.png'
import { confirmResetPassword } from "@aws-amplify/auth";




const OtpAuthentication = ({  route }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [otp, setOtp] = useState("");
    const btnRef = useRef();
    const {state} = useLocation()
    console.log(state)

    useEffect(() => {

        if (otp.length < 6) {
            btnRef.current.disabled = true;
            btnRef.current.style.backgroundColor = 'gray'
        } else {
            btnRef.current.disabled = false;
            btnRef.current.style.backgroundColor = 'green'
        }

    }, [otp.length]);

    const ResendOTP = () => {
        const email = JSON.parse(localStorage.getItem('useremail'));
        // using the same action for first time otp send and resend functionality.
        // dispatch(AppAction.ForgetPassword(email));

    }

    async function handleConfirmResetPassword(
        username,
        confirmationCode,
      ) {
        try {
            navigate('/reset-password',{state:{email:username,otp:confirmationCode}})
        //   await confirmResetPassword({ username, confirmationCode, newPassword });
        } catch (error) {
          console.log(error);
        }
      }

    const otpvalues = () => {
        console.log(state)
        if(state){
            handleConfirmResetPassword(state.email, otp)
            console.log(state.email, otp)
        }
        // dispatch(AppAction.VerifiedOtp(otp, () => {
        // }))
    };


    return (
        <Row style={{ backgroundColor: Colors.BgWhite }} className="h-screen">




            <Col className=" relative opacity-95 rounded-none " lg={12} md={12} sm={24} xs={24} style={{ background: Colors.White }}>

                <Row justify={'start'} className="h-full max-[900px]:w-4/5 w-3/5 mx-auto flex flex-col justify-center items-start px-2">
                    <Col className=" relative opacity-95 items-center justify-center mx-auto mb-0 md:mb-4 border-0 border-red-500">
                        <img src={loginLogo} className="w-auto h-[8rem] md:h-auto md:w-2/6 mx-auto" />
                        <Text.Basic text='Reset Password' className="block text-center text-[1.5rem] md:text-[2rem] " style={{ fontWeight: 700, color: Colors.DarkBlue, }} />
                        <Text.Basic text='Please enter 4 digit OTP Code recieved on the email' className="block text-center" style={{ fontWeight: 500, color: Colors.DarkBlue, fontSize: '1rem' }} />
                    </Col>
                    {/* // 2 bars on the right column. */}
                    {/* <img src={signinBar1} className="absolute h-full left-12  opacity-100 z-[-1]" /> */}
                    {/* <img src={signinBar2} className="absolute h-full right-12 opacity-100 z-[-1]" /> */}


                    <Row className="gap-2 w-full my-4" justify={'center'} align={'center'}>
                        <Col span={24} className="py-2" style={{}}>
                            <InputOtp
                                value={otp}
                                onChange={setOtp}
                                inputStyle={{ margin: '0 2px', padding: '0.5rem 1.2rem', color: Colors.Black, width: '60px', fontSize: '2rem', border: '1px solid #D7DBE8', backgroundColor: '#F9FAFC', borderRadius: '7px' }}
                                containerStyle={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                numInputs={'6'}
                                inputType={'number'}
                                skipDefaultStyles={true}
                            />

                        </Col>

                        <Button type="submit" ref={btnRef} onClick={otpvalues} className="w-full mx-auto h-12 mt-2" style={{ color: Colors.White, background: Colors.BgGradient }}>Submit</Button>
                        <p>Didn't receive the code? <span className="cursor-pointer underline" style={{ color: '#307CAE' }} onClick={ResendOTP}> Resend Code</span></p>

                    </Row>


                    <Row className="w-11/12 mt-5 mx-auto" align={'center'}  >
                        <p className=" cursor-pointer" onClick={() => navigate('/login')}>Back to login</p>
                    </Row>

                </Row>

            </Col>

            {/* <Col className=" flex flex-col justify-center align-center" lg={12} md={12} sm={0} xs={0}>
                <Row className="h-2/5 max-[900px]:h-2/6 max-[767px]:h-fit max-[767px]:pt-3 w-full mb-3" align={'center'}>
                    <img src={signin} className="w-1/2 h-full md:h-auto md:w-2/4" />
                </Row>
                <Row align={'center'} justify={'center'} className="signin-logo flex items-center	flex-col mx-auto">
                    <h1 className="text-5xl max-[900px]:text-4xl my-2 pt-1" style={{ color: Colors.White }}></h1>
                    <p className="text-center text-base md:text-xl w-60 max-[767px]:pb-2 relative mx-auto w-4/5 flex gap-2" style={{ color: Colors.White }}>
                        <img className="self-start" src={pointer} width={25} height={20} />
                        <p>Unveiling the Magic of Location</p>
                    </p>

                </Row>
            </Col> */}

            <Col className="hidden md:flex flex-col justify-center align-center max-[900px]:px-4 md:rounded-l-3xl border-0 border-red-500" lg={12} md={12} sm={0} xs={0} style={{ background: Colors.DarkBlue }}>
                <Row className="h-[31rem] relative max-[767px]:h-fit max-[767px]:pt-3 w-full mb-3  border-0 border-red-500" align={'center'}>
                    <img src={loginBgEllipse} className="w-1/2 h-full md:h-[85%] md:w-full object-contain object-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />
                    <img src={loginBg} className="w-1/2 h-full md:h-[100%] md:w-full object-contain object-center" />
                </Row>
                <Row align={'center'} justify={'center'} className="signin-logo flex items-center	flex-col mx-auto ">
                    <Text.Basic text="Smart Scanning Solution" className="min-[900px]:text-[2rem] text-[1.5rem] text-center font-semibold my-1 pt-1" style={{ color: Colors.White }}></Text.Basic>
                    {/* <img className="self-start" src={pointer} width={25} height={20} /> */}
                    <Text.Basic
                        text="Transform Documents into Audible or Editable Text"
                        className="text-base mx-6 max-[900px]:mx-0 font-extralight my-1 pt-1 text-center"
                        style={{ color: Colors.White }}>

                    </Text.Basic>


                </Row>
            </Col>


        </Row>
    )
};

export default OtpAuthentication;





























