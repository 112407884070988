import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Colors, Utils } from "./config";
import { ComponentsSample } from "./containers";
import { Loader, Popup } from "./components";
import AuthRoutes from "./config/routes/AuthRoutes.tsx";
import notification from "./config/firebase/notification";
import { Amplify } from 'aws-amplify';
import amplifyconfig from './aws-exports.js';
import { confirmSignUp, fetchUserAttributes, getCurrentUser, signIn, signOut, signUp } from 'aws-amplify/auth';
import "./App.css";
import AppMenu from "./config/routes/index.js";
import { BrowserRouter } from "react-router-dom";
import AppAction from "./store/actions/AppAction.js";

Amplify.configure(amplifyconfig);
const { saveSnackbarRef } = Utils;

const App = () => {

  // const loader = useSelector((state) => state?.AppReducer.loader);
  const dispatch = useDispatch();


  // alert("check user")	;
  // const { username, userId, signInDetails } = await getCurrentUser();
  // console.log(  "signInDetails ",signInDetails );
  // setUser({ username, userId, signInDetails: signInDetails || {} });

  // const user = await signUp({
    //   username: 'burhan@code-evenues.com', 
    //   password: 'Admin@123',});
    
    //============= signUp =================
  async function handleSignUp({ username, password, }) {
    try {
      const { isSignUpComplete, userId, nextStep } = await signUp({
        username,
        password,
        options: {
          userAttributes: {
            // email,
            // phone_number // E.164 number convention
          },
          // optional
          autoSignIn: true // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
        }
      });

      console.log(isSignUpComplete, userId, nextStep);
    } catch (error) {
      console.log('error signing up:', error);
    }
  }
  

  //============= confirmSignUp =================
  async function handleSignUpConfirmation({ username, confirmationCode }) {
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username,
        confirmationCode
      }
    );
    if(isSignUpComplete) {

      console.log('sign up complete', isSignUpComplete);
    }
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  }

  //============= SignIn =================
  async function handleSignIn({ username, password }) {
    console.log('signing in', username);

    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });
      console.log('signed in', isSignedIn);
      console.log('nextStep', nextStep);
    } catch (error) {
      console.log('error signing in', error);
    }
  }

  //============= fetchUserAttributes =================
  async function handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      console.log(userAttributes);
    } catch (error) {
      console.log(error);
    }
  }

  //============= signOut =================
  async function handleSignOut() {
    try {
      await signOut();
      console.log('signed out');
      dispatch(AppAction.isAuthenticated(false, () => {
        // navigate('/dashboard')
      }));
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  useEffect(() => {
    // handleSignUp({ username: 'muhammad.adil@code-avenue.com', password: 'Admin@123',});
    // handleSignUpConfirmation({ username: 'muhammad.adil@code-avenue.com', confirmationCode: '361469' });
    // handleSignIn({ username: 'muhammad.adil@code-avenue.com', password: 'Admin@123' });
    // handleFetchUserAttributes();
    // handleSignOut(); 
  }, []);

  return (
    <div>
      {/* {contextHolder} */}
         <AppMenu />
      {/* <Popup.Snackbar ref={(ref) => saveSnackbarRef(ref)} /> */}

      {/* {loader ? (
        <div
          style={{
            display: "flex",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Colors.BlackOpacity(0.7),
          }}
        >
          <Loader.Circular size={60} color={Colors.WhiteOpacity(0.7)} />
        </div>
      ) : null} */}

      {/* <ComponentsSample /> */}
    </div>
  );
};

export default App;
