import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Text } from "../../components";
import { Colors } from "../../config";
// import { AppAction } from "../../store/actions";
import './styles.css'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userLogin } from '../../config/Schema/Schema.ts'
import { confirmSignUp, fetchUserAttributes, getCurrentUser, signIn, signOut, signUp } from 'aws-amplify/auth';



// REACT ICONS
import loginLogo from '../../assets/images/loginLogo.png'
import eyePassword from '../../assets/images/eyePassword.png'
import loginBg from '../../assets/images/v3ctor-01 1.png'
import loginBgEllipse from '../../assets/images/Ellipse 1.png'
import AppAction from "../../store/actions/AppAction.js";


const SignIn = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const loader = useSelector((state) => state?.AppReducer.loader);
  const isAuthenticated = useSelector((state) => state?.AppReducer.isAuthenticated);
  console.log(loader)
  console.log(loader)

  useEffect(() => {
    
  }, []);

  

  const validationSchema = userLogin;
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validationSchema),
  });

  async function handleSignIn({ username, password }) {
    console.log('signing in', username);
    
    // console.log('isAuthenticated', isAuthenticated);
    // console.log('loader', loader);
    
    // console.log('loader', loader);
    try {
        const { isSignedIn, nextStep } = await signIn({ username, password });
        console.log('signed in', isSignedIn);
        console.log('nextStep', nextStep);
        dispatch(AppAction.isAuthenticated(true, () => {
          // navigate('/dashboard')
        }));
        // navigate("/dashboard");
      } catch (error) {
          console.log('error signing in', error);
        }
    }
    console.log('isAuthenticated', isAuthenticated);

  const LoginForm = (data) => {

    console.log("data i am getting signin", data)
    const { email, password } = data;
    handleSignIn({ username: email, password: password });
  }

  return (
    <Row style={{ backgroundColor: Colors.BgWhite }} className="h-screen">

      <Col className=" relative opacity-95 flex rounded-none  border-0 border-red-500" lg={12} md={12} sm={24} xs={24} style={{ background: "#fefeff" }}>

        <Row justify={'start'} className=" max-[900px]:w-4/5 w-3/5 mx-auto flex flex-col justify-center items-start px-2">

          <Col className=" relative opacity-95 items-center justify-center mx-auto mb-0 md:mb-4 border-0 border-red-500">
            <img src={loginLogo} className="w-auto h-[8rem] md:h-auto md:w-2/6 mx-auto" />
            <Text.Basic text='Welcome to Admin' className="block text-center text-[1.5rem] md:text-[2rem] " style={{ fontWeight: 700, color: Colors.DarkBlue, }} />
            <Text.Basic text='Login to Your Account' className="block text-center" style={{ fontWeight: 500, color: Colors.DarkBlue, fontSize: '1rem' }} />
          </Col>

          {/* // 2 bars on the right column. */}
          {/* <img src={signinBar1} className="absolute h-full left-12  opacity-100 z-[-1]" /> */}
          {/* <img src={signinBar2} className="absolute h-full right-12 opacity-100 z-[-1]" /> */}


          <form className="mt-10 md:mt-2 w-full border-0 border-red-500" >

            <label className="font-semibold" style={{ fontWeight: 600, color: Colors.DarkBlue, }}>Email Address</label>
            <Row className="w-full pb-5">
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <Col span={24} className="relative">
                    <input type="email" placeholder="Enter your username" style={{ border: `1px solid #D7DBE8`, background: Colors.LightGray }} className="px-2 py-3 rounded w-full outline-0 login-placeholder-color"  {...field} />
                    {/* <img className="absolute h-4 w-4 right-4 top-4" src={email} /> */}
                    {fieldState.error && <p style={{ color: Colors.Error }}>{fieldState.error.message}</p>}
                  </Col>
                )}
              />
            </Row>

            <label className="font-semibold" style={{ fontWeight: 600, color: Colors.DarkBlue, }}>Password</label>
            <Row className="w-full">
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <Col span={24} className="relative">
                    <input type={showPassword ? 'text' : 'password'} style={{ border: `1px solid #D7DBE8`, background: Colors.LightGray }} className="px-2 py-3 rounded w-full outline-0 login-placeholder-color"  {...field} />
                    <img className="absolute h-4 w-4 right-4 top-4 cursor-pointer" src={showPassword ? eyePassword : eyePassword} onClick={() => setShowPassword(!showPassword)} style={{ color: Colors.Gray, }} />
                    {fieldState.error && <p style={{ color: Colors.Error }}>{fieldState.error.message}</p>}
                  </Col>
                )}
              />
            </Row>

            <Row className="w-full mt-2 border-0 border-red-500" >
              <p className="ml-auto border-0 border-red-500 cursor-pointer" onClick={() => navigate('/forget-password')}>Forgot Password?</p>
            </Row>
            
            
            <button  
              type='submit' 
              onClick={handleSubmit((data)=>LoginForm(data))} 
              value='Login' 
              // disabled={loader ? true : false} 
              className='w-full mt-4 h-11 rounded cursor-pointer hover:opacity-95' 
              style={{ background: "linear-gradient(113.29deg, #5AF4FF -6.79%, #2F7AAC 79.93%)", color: Colors.White }} 
            >
              Login
            </button>
                
          </form>
                
          {/* <Row className="w-11/12 mt-5" >
            <p className="mx-auto cursor-pointer" onClick={() => navigate('/forget-password')}>Forgot Password?</p>
          </Row> */}

        </Row>

      </Col>

      <Col className="hidden md:flex flex-col justify-center align-center md:rounded-l-3xl max-[900px]:px-4 border-0 border-red-500" lg={12} md={12} sm={0} xs={0} style={{ background: Colors.DarkBlue }}>
        <Row className="h-[31rem] relative max-[767px]:h-fit max-[767px]:pt-3 w-full mb-3  border-0 border-red-500" align={'center'}>
          <img src={loginBgEllipse} className="w-1/2 h-full md:h-[85%] md:w-full object-contain object-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />
          <img src={loginBg} className="w-1/2 h-full md:h-[100%] md:w-full object-contain object-center" />
        </Row>
        <Row align={'center'} justify={'center'} className="signin-logo flex items-center	flex-col mx-auto ">
          <Text.Basic text="Smart Scanning Solution" className="min-[900px]:text-[2rem] text-[1.5rem] text-center font-semibold my-1 pt-1" style={{ color: Colors.White }}></Text.Basic>
          {/* <img className="self-start" src={pointer} width={25} height={20} /> */}
          <Text.Basic
            text="Transform Documents into Audible or Editable Text"
            className="text-base mx-6 max-[900px]:mx-0 font-extralight my-1 pt-1 text-center"
            style={{ color: Colors.White }}>

          </Text.Basic>


        </Row>
      </Col>

    </Row>
  )
};

export default SignIn;
