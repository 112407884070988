import Basic from "./Basic.tsx";
import Floating from "./Floating.tsx";
import Upload from "./Upload.tsx";
import DropDown from "./DropDown.tsx";

export default {
  Basic,
  Upload,
  Floating,
  DropDown
};
