// const Images = {
//     Logo: require("../../assets/images/logo.png")
// }

// export default Images;

const Images: { [key: string]: any } = {
    Logo: require("../../assets/images/logo.png"),
  };
  
  export default Images;
  