// import * as yup from "yup";

// // for user login or sign in validation schema
// export const userLogin = yup.object().shape({
//     email: yup.string()
//         .email('Invaild Email')
//         .required('Email is required')
//         .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,'Please enter a valid email'),
//     password: yup.string()
//         .required('Password is required')
//         // .min(8)
//         // .max(32)
//         // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
//         // .matches(/[0-9]/, 'Password must contain at least one number')
//         // .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')

// });

// // for useremail for forget password
// export const userEmail = yup.object().shape({
//     email: yup.string().email('Invaild Email').required('Email is required'),
// });

// // for password matching in forget password.
// export const passwordVerification = yup.object().shape({
//     password: yup.string()
//         .min(8)
//         .max(32)
//         .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
//         .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
//         .matches(/[0-9]/, 'Password must contain at least one number')
//         .required('Password is required'),
//     confirm_password: yup.string().label('confirm password').required().oneOf([yup.ref('password'), null], 'Passwords do not match'),

// });

// // for password reset from profile.
// export const changePassword = yup.object().shape({
//     old_Password: yup.string()
//         .required('Password is required')
//         // .min(8)
//         // .max(32)
//         // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
//         // .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
//         // .matches(/[0-9]/, 'Password must contain at least one number')
//         ,

//     newPassword: yup.string()
//         .min(8)
//         .max(32)
//         .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
//         .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
//         .matches(/[0-9]/, 'Password must contain at least one number')
//         .required('Password is required'),

//     confirmPassword: yup.string().label('confirm password').required().oneOf([yup.ref('newPassword'), null], 'Passwords do not match'),

// })

import * as yup from "yup";

// TypeScript interface for form values
interface UserLoginValues {
    email: string;
    password: string;
}

interface UserEmailValues {
    email: string;
}

interface PasswordVerificationValues {
    password: string;
}

interface ChangePasswordValues {
    old_Password: string;
    newPassword: string;
    confirmPassword: string;
}

// For user login or sign-in validation schema
export const userLogin: yup.Schema<UserLoginValues> = yup.object().shape({
    email: yup.string()
        .email('Invalid Email')
        .required('Email is required')
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, 'Please enter a valid email'),
    password: yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .max(32, 'Password must be at most 32 characters long')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .required('Password is required'),
    // Uncomment the following lines if additional password validation is needed
    // .min(8)
    // .max(32)
    // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    // .matches(/[0-9]/, 'Password must contain at least one number')
    // .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
});

// For user email for forget password
export const userEmail: yup.Schema<UserEmailValues> = yup.object().shape({
    email: yup.string().email('Invalid Email').required('Email is required'),
});

// For password matching in forget password
export const passwordVerification: yup.Schema<PasswordVerificationValues> = yup.object().shape({
    password: yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .max(32, 'Password must be at most 32 characters long')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .required('Password is required')
});

// For password reset from profile
export const changePassword: yup.Schema<ChangePasswordValues> = yup.object().shape({
    old_Password: yup.string()
        .required('Old Password is required')
    // Uncomment the following lines if additional password validation is needed
    // .min(8)
    // .max(32)
    // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    // .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    // .matches(/[0-9]/, 'Password must contain at least one number')
    ,
    newPassword: yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .max(32, 'Password must be at most 32 characters long')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .required('New Password is required'),
    confirmPassword: yup.string().label('Confirm Password').required('Confirm Password is required').oneOf([yup.ref('newPassword'), ''], 'Passwords do not match'),
});


