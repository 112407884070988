import { Card } from 'antd';
import React from 'react';

interface AppProps {
  content?: React.ReactNode;
  bordered?: boolean;
  style?: React.CSSProperties;
}

const App: React.FC<AppProps> = ({
  content = "Card",
  bordered = false,
  style
}) => (
  <Card bordered={bordered} style={style}>
    {content}
  </Card>
);

export default App;
