// import React from 'react'
// import { Layout, Avatar } from 'antd'
// import Colors from '../../config/colors'
// import Button from '../Button'
// import { useNavigate } from 'react-router'


// // REACT ICONS
// import { UserOutlined } from '@ant-design/icons';



// const NavHeader = () => {
//     const { Header } = Layout;
//     const navigate = useNavigate();
//     const userData = JSON.parse(localStorage.getItem('user'));
//     return (
//         <Header
//             className='flex justify-end items-center py-0 px-4'
//             style={{
//                 background: Colors.White,
//             }}
//         >

//             <Button.Basic
//                 onClick={() => navigate('/user-profile')}
//                 className="flex items-center gap-1"
//                 buttonStyle={{border:'1px solid #E2EECA',height:'50px'}}
//                 icon={userData?.imageUrl ? <Avatar size={'large'} src={userData?.imageUrl} /> : <Avatar size={'large'} icon={<UserOutlined />} />}
//                 text={userData?.username ? userData?.username : 'Admin'} 
//                 size={'large'} 
//             />

//         </Header>
//     )
// }

// export default NavHeader

// // user-button flex items-center justify-center h-14 gap-1.5

import React from 'react';
import { Layout,  } from 'antd';
import Colors from '../../config/colors/index.ts';
import Button from '../Button';
import Avatar from '../Avatar';
import text from '../Text';
import { useNavigate } from 'react-router-dom';
import { userProfile } from '../../assets/images/userProfile.png';
import logo from '../../assets/images/logo.png'


// REACT ICONS
import { UserOutlined } from '@ant-design/icons';

const NavHeader: React.FC = () => {
  const { Header } = Layout;
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem('user') || '{}');

  return (
    <Header
      className='flex justify-end items-center py-0 px-4'
      style={{
        background: Colors.White,
      }}
    >
      <div className='flex items-center'>

        {/* <Button.Basic
          // onClick={() => navigate('/user-profile')}
          className="flex items-center gap-1"
          buttonStyle={{ border: '0px solid #E2EECA', height: '50px' }}
          icon={userData?.imageUrl ? <Avatar size={'large'} src={userData.imageUrl} /> : <Avatar size={'large'} icon={<UserOutlined />} />}
          // icon={<Avatar size={'large'} src={userProfile} />}
          // text={userData?.username ? userData.username : 'Admin'}
          size={'large'}
        /> */}
        {userData?.imageUrl ? <Avatar.Basic src={userData.imageUrl} className='w-10 h-10' /> : <Avatar.Basic icon={<UserOutlined />} className='w-10 h-10' />}
        <div className='flex flex-col ml-2 mr-4'>
          <text.Basic className='font-bold text-xs inline' text={userData?.username ? userData?.username : 'James William'} />
          <text.Basic className='font-semibold text-xs inline' style={{ color: Colors.Blue }} text={userData?.username ? userData?.username : 'Admin'} />
        </div>
      </div>
    </Header>
  );
};

export default NavHeader;
