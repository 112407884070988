// const Colors = {
//     PlaceHolder: (opacity = "0.5") => `rgba(235, 235, 235, ${opacity})`,
//     BlackOpacity: (opacity = "0.5") => `rgba(0, 0, 0, ${opacity})`,
//     WhiteOpacity: (opacity = "0.5") => `rgba(255, 255, 255, ${opacity})`,
//     Black:"#000000",
//     Transparent: "transparent",
//     Primary: "#d94826",
//     Secondary: "#000000",
//     DarkGray: "#1E1E22",
//     White: "#ffffff",
//     Text: "#ffffff",
//     Danger: "#FF494C",
//     Green: "#307cae",
//     LightGreen: "#E2EECA",
//     Gray:"#9B9CA9",
//     LightGray: "#F5F5F5", 
//     LightWhite: "#F9FAFC",
//     Error:"red",
//     Low:"#F3B700",
//     SwitchEnable:"#E2EECA",
//     SwitchDisable:"#FF606B1A",
//     GrayFont:"#6B6F74",
//     TextArea:"#f5f5f5ff"
// }

// export default Colors;

const Colors = {
    PlaceHolder: (opacity: string = "0.5"): string => `rgba(235, 235, 235, ${opacity})`,
    BlackOpacity: (opacity: string = "0.5"): string => `rgba(0, 0, 0, ${opacity})`,
    WhiteOpacity: (opacity: string = "0.5"): string => `rgba(255, 255, 255, ${opacity})`,
    Black: "#000000",
    Transparent: "transparent",
    Primary: "#1F3C5C",
    Secondary: "#000000",
    BgGradient: "linear-gradient(113.29deg, #5AF4FF -6.79%, #2F7AAC 79.93%)",
    DarkBlue:"#1F3C5C",
    Blue:"#307cae",
    Gray:"#858fa0",
    GrayText:"#5b687e",
    LightGray:"#f6f7f9",
    BgWhite:"#FFFFFF",
    DarkGray: "#1E1E22",
    White: "#ffffff",
    Text: "#ffffff",
    Danger: "#FF494C",
    Green: "#307cae",
    LightGreen: "#E2EECA",
    // Gray: "#9B9CA9",
    // LightGray: "#F5F5F5",
    LightWhite: "#F9FAFC",
    Error: "red",
    Low: "#F3B700",
    SwitchEnable: "#E2EECA",
    SwitchDisable: "#FF606B1A",
    GrayFont: "#6B6F74",
    TextArea: "#f5f5f5ff"
  };
  
  export default Colors;
  